<template>
    <section ref="slider" class="simple-slider" :class="orientation">
        <ContainerContent class="slider-container-content">
            <div class="slider">
                <div
                    v-if="hasNavigationButtons"
                    :class="[
                        'navigation-buttons',
                        navigationButtonsClass,
                        horizontalNavigationButtons ? 'horizontal-nav' : '',
                    ]"
                    :style="[verticalNavigationButtonsStyles, navigationButtonsStyles]"
                >
                    <ButtonIcon
                        v-for="(button, key) in navigationButtons"
                        :key="key"
                        :variant="BUTTON_VARIANT"
                        :disabled="button.isDisabled"
                        class="navigation-button"
                        @click="onNavigationButtonClick(button.direction)"
                    >
                        <template #default>
                            <component :is="button.icon" />
                        </template>
                    </ButtonIcon>
                </div>

                <component
                    :is="tag"
                    ref="wrapper"
                    class="wrapper"
                    :class="[
                        `items-count-${itemsCount}`,
                        sliderClass,
                        isScrolling ? 'is-scrolling' : '',
                        orientation,
                    ]"
                >
                    <slot name="slides" />
                </component>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import { ITEMS_COUNT } from '@configs/simple-slider';
import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    SLIDER_ORIENTATIONS,
} from '@types/SimpleSlider';

import approximatelyEqual from '@assets/approximatelyEqual';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

import { ChevronDown, ChevronUp, ChevronRight, ChevronLeft } from '@modivo-ui/icons/v2/navigation';

const SCROLL_THROTTLE = 100;
const RESIZE_DEBOUNCE = 410;
const TOUCH_START_DEBOUNCE = 100;
const TOUCH_END_DEBOUNCE = 500;
const HIDE_SCROLL_AFTER = 1000;

export default {
    name: 'SimpleSlider',

    components: {
        ButtonIcon,
        ContainerContent,
        ChevronDown,
        ChevronUp,
        ChevronRight,
        ChevronLeft,
    },

    props: {
        tag: {
            type: String,
            default: 'ul',
        },

        hasNavigationButtons: {
            type: Boolean,
            default: true,
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        additionalScrollOffset: {
            type: Number,
            default: 0,
        },

        navigationButtonsStyles: {
            type: Object,
            default: () => ({}),
        },

        navigationButtonsClass: {
            type: String,
            default: '',
        },

        horizontalNavigationButtons: {
            type: Boolean,
            default: true,
        },

        sliderClass: {
            type: String,
            default: '',
        },

        hasAutoPlay: {
            type: Boolean,
            default: false,
        },

        autoPlayDelay: {
            type: Number,
            default: 2000,
        },

        pauseAutoPlayOnMouseOver: {
            type: Boolean,
            default: false,
        },

        orientation: {
            type: String,
            default: ORIENTATION_HORIZONTAL,
            validator: value => Object.values(SLIDER_ORIENTATIONS).includes(value),
        },

        changeSlideByOne: {
            type: Boolean,
            default: true,
        },

        scrollThrottle: {
            type: Number,
            default: SCROLL_THROTTLE,
        },
    },

    data: () => ({
        slidesWidth: [],
        currentPosition: 0,
        wrapperScrollWidth: 0,
        wrapperVisibleWidth: 0,
        wrapperScrollHeight: 0,
        wrapperVisibleHeight: 0,
        observer: null,
        autoPlayInterval: null,
        slideWidth: 0,
        slideHeight: 0,
        slidesRef: null,
        singleSlide: null,
        isScrolling: false,
        isScrollingTimeout: null,
    }),

    computed: {
        ...mapState(['isMobile']),

        isSliderVertical() {
            return this.orientation === ORIENTATION_VERTICAL;
        },

        navigationButtons() {
            if (this.isSliderVertical) {
                return {
                    prev: {
                        isDisabled: this.isBoundedTop,
                        direction: -1,
                        icon: ChevronUp,
                    },
                    next: {
                        isDisabled: this.isBoundedBottom,
                        direction: 1,
                        icon: ChevronDown,
                    },
                };
            }

            return {
                prev: {
                    isDisabled: this.isBoundedLeft,
                    direction: -1,
                    icon: ChevronLeft,
                },
                next: {
                    isDisabled: this.isBoundedRight,
                    direction: 1,
                    icon: ChevronRight,
                },
            };
        },

        verticalNavigationButtonsStyles() {
            if (!this.isSliderVertical) {
                return;
            }

            if (this.slidesRef) {
                return this.wrapperScrollHeight > this.wrapperVisibleHeight
                    ? { display: 'flex' }
                    : { display: 'none' };
            }

            return {};
        },

        isBoundedTop() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedBottom() {
            return approximatelyEqual(
                this.wrapperScrollHeight - this.wrapperVisibleHeight,
                this.currentPosition,
                5
            );
        },

        isBoundedLeft() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedRight() {
            return approximatelyEqual(
                this.wrapperScrollWidth - this.wrapperVisibleWidth,
                this.currentPosition,
                5
            );
        },

        horizontalSlideChange() {
            return this.changeSlideByOne ? this.slideWidth : this.wrapperVisibleWidth;
        },
    },

    mounted() {
        this.init();

        this.onResizeFn = debounce(this.resizeHandler, RESIZE_DEBOUNCE);
        this.onScrollFn = throttle(this.calcOnScroll, this.scrollThrottle);
        this.onTouchStartFn = debounce(this.onTouchStart, TOUCH_START_DEBOUNCE);
        this.onTouchEndFn = debounce(this.onTouchEnd, TOUCH_END_DEBOUNCE);

        this.attachMutationObserver();

        this.$refs.wrapper.addEventListener('touchstart', this.onTouchStartFn);
        this.$refs.wrapper.addEventListener('touchend', this.onTouchEndFn);

        this.$refs.wrapper.addEventListener('scroll', this.onScrollFn);
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.SECONDARY;
    },

    beforeDestroy() {
        this.onResizeFn.cancel();
        this.onScrollFn.cancel();
        this.onTouchStartFn.cancel();
        this.onTouchEndFn.cancel();
        this.observer.disconnect();
        this.$refs.wrapper.removeEventListener('touchstart', this.onTouchStartFn);
        this.$refs.wrapper.removeEventListener('touchend', this.onTouchEndFn);
        this.$refs.wrapper.removeEventListener('scroll', this.onScrollFn);
        window.removeEventListener('resize', this.onResizeFn, false);
        this.removeAutoPlay();
    },

    methods: {
        init(reInitSlidesRef = false) {
            if (this.$refs.wrapper.children.length) {
                if (!this.slidesRef || reInitSlidesRef) {
                    this.slidesRef = Array.from(this.$refs.wrapper.children);
                }

                [this.singleSlide] = this.slidesRef;

                this.slideWidth = this.singleSlide.offsetWidth;
                this.slideHeight = this.singleSlide.offsetHeight;
            }

            this.calcOnInit();
            this.initAutoPlay();
        },

        resizeHandler() {
            this.removeAutoPlay();
            this.init();
        },

        onTouchStart() {
            if (this.hasAutoPlay) {
                return;
            }

            if (this.isScrollingTimeout) {
                clearTimeout(this.isScrollingTimeout);
                this.isScrollingTimeout = null;
            }

            this.isScrolling = true;
        },

        onTouchEnd() {
            if (this.hasAutoPlay) {
                return;
            }

            if (this.isScrollingTimeout) {
                return;
            }

            this.isScrollingTimeout = setTimeout(() => {
                this.isScrolling = false;

                this.$emit('on-touch-end');
            }, HIDE_SCROLL_AFTER);
        },

        calcOnInit() {
            if (this.isSliderVertical) {
                this.calcWrapperHeight();
                this.calcSlidesHeight();
            } else {
                this.calcWrapperWidth();
                this.calcSlidesWidth();
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcOnScroll() {
            if (!this.$refs.wrapper) {
                return;
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        initAutoPlay() {
            if (
                this.isMobile ||
                !this.hasAutoPlay ||
                this.wrapperScrollWidth <= this.wrapperVisibleWidth
            ) {
                if (this.autoPlayInterval) {
                    this.stopAutoPlay();
                    this.removeAutoPlayMouseEvents();
                }

                return;
            }

            this.autoPlayInterval = setInterval(() => {
                if (this.isBoundedRight) {
                    this.scrollTo(0);
                } else {
                    this.changeSlide(1);
                }
            }, this.autoPlayDelay);

            if (this.pauseAutoPlayOnMouseOver) {
                this.addAutoPlayMouseEvents();
            }
        },

        stopAutoPlay() {
            clearInterval(this.autoPlayInterval);
        },

        calcWrapperWidth() {
            this.wrapperScrollWidth = this.$refs.wrapper.scrollWidth;
            this.wrapperVisibleWidth = this.$refs.wrapper.offsetWidth;
        },

        calcSlidesWidth() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesWidth = childNodes.map(node => ({
                offsetLeft: node.offsetLeft,
                width: node.offsetWidth,
            }));
        },

        calcWrapperHeight() {
            this.wrapperScrollHeight = this.$refs.wrapper.scrollHeight;
            this.wrapperVisibleHeight = this.$refs.wrapper.offsetHeight;
        },

        calcSlidesHeight() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesHeight = childNodes.map(node => ({
                offsetTop: node.offsetTop,
                height: node.offsetHeight,
            }));
        },

        calcActiveSlide() {
            const {
                isSliderVertical,
                slidesHeight,
                slidesWidth,
                currentPosition,
                slidesRef,
            } = this;
            const slides = isSliderVertical ? slidesHeight : slidesWidth;
            let activeSlideIndex = 0;

            const activeIndex = slides.findIndex(slide => {
                const slidePosition = isSliderVertical ? slide.offsetTop : slide.offsetLeft;
                const slideHalfSize = 0.5 * (isSliderVertical ? slide.height : slide.width);

                return (
                    currentPosition >= slidePosition - slideHalfSize &&
                    currentPosition < slidePosition + slideHalfSize
                );
            });

            if (activeIndex !== -1) {
                activeSlideIndex = Math.max(activeIndex, 0);
                this.$emit('active-slide', activeSlideIndex);
            }

            if (slidesRef) {
                slidesRef.forEach((child, index) => {
                    child.classList.toggle('active', index === activeSlideIndex);
                });
            }
        },

        calcCurrentPosition() {
            const { scrollTop = 0, scrollLeft = 0 } = this.$refs.wrapper;

            this.currentPosition = this.isSliderVertical ? scrollTop : scrollLeft;
        },

        onNavigationButtonClick(direction = 1) {
            this.removeAutoPlay();
            this.changeSlide(direction);
            this.$emit('navigation-button-click');
        },

        addAutoPlayMouseEvents() {
            this.$refs.slider.addEventListener('mouseenter', this.stopAutoPlay);
            this.$refs.slider.addEventListener('mouseleave', this.initAutoPlay);
        },

        removeAutoPlayMouseEvents() {
            this.$refs.slider.removeEventListener('mouseenter', this.stopAutoPlay);
            this.$refs.slider.removeEventListener('mouseleave', this.initAutoPlay);
        },

        removeAutoPlay() {
            if (this.hasAutoPlay) {
                this.stopAutoPlay();

                if (this.pauseAutoPlayOnMouseOver) {
                    this.removeAutoPlayMouseEvents();
                }
            }
        },

        changeSlide(direction = 1) {
            const offset = this.isSliderVertical ? this.slideHeight : this.horizontalSlideChange;

            this.scroll(direction * (offset + this.additionalScrollOffset));
        },

        scroll(position = 0) {
            const direction = this.isSliderVertical ? 'top' : 'left';

            this.$refs.wrapper.scrollBy({
                [direction]: position,
                behavior: 'smooth',
            });
        },

        scrollTo(position = 0) {
            const direction = this.isSliderVertical ? 'top' : 'left';

            this.$refs.wrapper.scroll({
                [direction]: position,
                behavior: 'smooth',
            });
        },

        attachMutationObserver() {
            this.observer = new MutationObserver(() => {
                this.init(true);
            });
            this.observer.observe(this.$refs.wrapper, {
                childList: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$wrapper-padding-bottom: $tailwindcss-spacing-4;

.simple-slider {
    @apply w-full;

    &.vertical {
        .wrapper {
            @apply flex-col overflow-y-auto overflow-x-hidden;
            -webkit-scroll-snap-type: y mandatory;
            scroll-snap-type: y mandatory;
        }

        &:deep() .simple-slider-slide {
            @apply z-2;
        }
    }

    .slider {
        @apply w-full relative;
    }

    .navigation-buttons {
        @apply absolute hidden z-2;

        &.horizontal-nav {
            @apply ml-1;
            z-index: unset;
            width: calc(100% - #{$tailwindcss-spacing-2});

            .navigation-button {
                @apply absolute z-2;

                &[disabled] {
                    @apply hidden;
                }

                &:last-of-type {
                    @apply right-0;
                }
            }
        }
    }

    .wrapper {
        @apply flex overflow-x-auto overflow-y-hidden;
        @apply overscroll-x-contain;
        -webkit-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            @apply hidden;
        }
    }

    &:deep() {
        .slider-container-content {
            @apply px-0;
        }
    }

    @screen lg {
        .navigation-buttons {
            @apply flex;
        }
    }

    @screen container {
        .navigation-buttons {
            &.horizontal-nav {
                @apply ml-2;
                width: calc(100% - #{$tailwindcss-spacing-3});
            }
        }
    }
}
</style>
