<template>
    <component
        :is="tag"
        ref="slide"
        :data-test-id="SIMPLE_SLIDER_SLIDE"
        :class="{
            'with-scroll-snap-on-mobile': scrollSnapOnMobile,
            'with-scroll-snap-on-desktop': scrollSnapOnDesktop,
        }"
        class="simple-slider-slide"
        tabindex="0"
    >
        <slot />
    </component>
</template>

<script>
import { SIMPLE_SLIDER_SLIDE } from '@types/AutomaticTestIDs';

export default {
    name: 'SimpleSliderSlide',

    props: {
        /**
         * Custom tag
         */
        tag: {
            type: String,
            default: 'li',
        },

        scrollSnapOnMobile: {
            type: Boolean,
            default: false,
        },

        scrollSnapOnDesktop: {
            type: Boolean,
            default: true,
        },
    },

    beforeCreate() {
        this.SIMPLE_SLIDER_SLIDE = SIMPLE_SLIDER_SLIDE;
    },
};
</script>

<style lang="scss" scoped>
.simple-slider-slide {
    @apply snap-always;

    &:focus {
        @apply outline-none;
    }

    &.with-scroll-snap-on-mobile {
        @apply snap-start;
    }

    &.with-scroll-snap-on-desktop {
        @screen lg {
            @apply snap-start;
        }
    }
}
</style>
