var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"slider",staticClass:"simple-slider",class:_vm.orientation},[_c('ContainerContent',{staticClass:"slider-container-content"},[_c('div',{staticClass:"slider"},[(_vm.hasNavigationButtons)?_c('div',{class:[
                    'navigation-buttons',
                    _vm.navigationButtonsClass,
                    _vm.horizontalNavigationButtons ? 'horizontal-nav' : '',
                ],style:([_vm.verticalNavigationButtonsStyles, _vm.navigationButtonsStyles])},_vm._l((_vm.navigationButtons),function(button,key){return _c('ButtonIcon',{key:key,staticClass:"navigation-button",attrs:{"variant":_vm.BUTTON_VARIANT,"disabled":button.isDisabled},on:{"click":function($event){return _vm.onNavigationButtonClick(button.direction)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(button.icon,{tag:"component"})]},proxy:true}],null,true)})}),1):_vm._e(),_vm._v(" "),_c(_vm.tag,{ref:"wrapper",tag:"component",staticClass:"wrapper",class:[
                    `items-count-${_vm.itemsCount}`,
                    _vm.sliderClass,
                    _vm.isScrolling ? 'is-scrolling' : '',
                    _vm.orientation,
                ]},[_vm._t("slides")],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }