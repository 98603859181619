<template>
    <SimpleSliderSlide
        class="products-simple-slider-slide"
        :class="`items-count-${itemsCount}`"
        :scroll-snap-on-desktop="false"
    >
        <ProductCardMedium
            :product="productDesignSystem"
            :is-omnibus-enabled="isOmnibusEnabled"
            :is-picture-lazy="true"
            :is-sponsored="product.isSponsored"
            :is-favourite="isAdded"
            :sponsored-label="$t('Sponsored')"
            :omnibus-regular-label="$t('Regular price')"
            :omnibus-minimal-label="$t('Lowest price')"
            @toggle-favourite="toggle(true)"
            @sponsored-click="openAdvertisementModal()"
            @click.exact.native="$emit('product-click', { product, mouseEvent: $event })"
            @click.ctrl.exact.native="$emit('product-click', { product, mouseEvent: $event })"
            @click.exact.native.middle="
                $emit('product-click', {
                    product,
                    mouseEvent: { ...$event, middleKey: true },
                })
            "
        >
            <template v-if="productDesignSystem.price.isPriceMinimal" #price-prefix>
                {{ $t('from-number') }}
            </template>
        </ProductCardMedium>
    </SimpleSliderSlide>
</template>

<script>
import { ITEMS_COUNT } from '@configs/simple-slider';
import { MODAL_ADVERTISEMENT_INFORMATION, MODAL_TYPE } from '@configs/modals';

import {
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
    IS_CURRENCY_BEFORE_ENABLED,
    IS_GOOD_PRICE_ENABLED,
} from '@localeConfig/keys';

import { COLORS } from '@search/configs/filter-main-colors';

import { IMAGE_TYPE_PRODUCT_CARD } from '@types/Image';
import {
    BADGE_EDGE_VARIANTS_MAP,
    DISCOUNT_TYPES,
    TYPES_FOR_INTERNAL_OFFERS_ONLY,
} from '@types/ProductBadge';
import { OFFER } from '@types/AdvertisementModal';

import { getProductImage } from '@assets/images';
import { getProductBadgeConfig } from '@assets/product-badge-config';

import AddToWishlist from '@mixins/AddToWishlist';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

import { ProductCardMedium } from '@modivo-ui/components/ProductCardMedium/v1';

export default {
    name: 'ProductsSimpleSliderSlide',

    components: {
        SimpleSliderSlide,
        ProductCardMedium,
    },

    mixins: [AddToWishlist],

    props: {
        product: {
            type: Object,
            required: true,
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },
    },

    computed: {
        isOmnibusEnabled() {
            return this.$configProvider.getConfig(IS_OMNIBUS_STRICT_ENABLED);
        },

        productBadgeConfig() {
            const { price: productPrice, productBadgeConfig: productBadges = [] } = this.product;

            return getProductBadgeConfig({
                productBadges,
                productPrice,
                isOmnibusEnabled: this.isOmnibusEnabled,
                $t: this.$t.bind(this),
            });
        },

        productDesignSystem() {
            const { product, isOmnibusEnabled } = this;

            const isCentsEnabled = !this.$configProvider.getConfig(IS_CUT_OFF_THE_CENTS_ENABLED);
            const isRegularPriceEnabled = this.$configProvider.getConfig(IS_REGULAR_PRICE_ENABLED);
            const isCurrencyBefore = this.$configProvider.getConfig(IS_CURRENCY_BEFORE_ENABLED);

            const {
                discount,
                promotional,
                regular,
                omnibus,
                isMinimal: isPriceMinimal,
            } = product.price;

            const regularAmount = regular?.amount || null;
            const promotionalAmount = promotional?.amount || null;
            const omnibusAmount = omnibus?.amount || null;

            const isDiscountFromPrice = isOmnibusEnabled
                ? omnibusAmount > promotionalAmount
                : regularAmount > promotionalAmount;

            const isDiscounted = discount && !isPriceMinimal ? isDiscountFromPrice : false;

            const arePricesTheSame =
                promotionalAmount === regularAmount && promotionalAmount === omnibusAmount;

            const isCatalogPromotion = regularAmount !== promotionalAmount;

            const isPricesOnGoodPriceEnabled =
                this.$configProvider.getConfig(IS_GOOD_PRICE_ENABLED) && isCatalogPromotion;

            const regularPrice =
                isRegularPriceEnabled && regularAmount > promotionalAmount
                    ? regular?.formatted
                    : null;

            const photoBadges = [];
            let promoBadge = null;

            this.productBadgeConfig.forEach(badge => {
                if (
                    !TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type) &&
                    (!DISCOUNT_TYPES.includes(badge.type) || !isPriceMinimal)
                ) {
                    photoBadges.push(badge);

                    return;
                }

                if (
                    !promoBadge &&
                    !isPriceMinimal &&
                    TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type)
                ) {
                    promoBadge = {
                        label: badge.content,
                        color: badge.color,
                        backgroundColor: badge.backgroundColor,
                    };
                }
            });

            return {
                id: product.id,
                brand: product.brandName,
                name: product.shortName || product.nameDisplay,
                url: product.url,
                color: COLORS[product?.values?.main_color?.value.code],
                price: {
                    final: product.price.promotional.formatted,
                    regular: regularPrice,
                    minimal:
                        isOmnibusEnabled &&
                        omnibusAmount &&
                        !arePricesTheSame &&
                        !isPriceMinimal &&
                        (isPricesOnGoodPriceEnabled || omnibusAmount > promotionalAmount)
                            ? product.price.omnibus?.formatted || ''
                            : null,
                    isCurrencyBefore,
                    isDiscounted,
                    isCentsEnabled,
                    isPriceMinimal,
                },
                images: product.images.map((_, index) => {
                    const image = getProductImage(
                        product,
                        index,
                        [IMAGE_TYPE_PRODUCT_CARD],
                        IMAGE_TYPE_PRODUCT_CARD
                    );

                    image.src = image.url;
                    delete image.url;

                    return image;
                }),
                badges: photoBadges.map(({ type, content: label }) => ({
                    type: BADGE_EDGE_VARIANTS_MAP[type],
                    label,
                })),
                promoBadge,
            };
        },
    },

    methods: {
        openAdvertisementModal() {
            const { companyAccountId } = this.product;

            if (!companyAccountId) {
                return;
            }

            this.$modals.open(MODAL_ADVERTISEMENT_INFORMATION, {
                [MODAL_TYPE]: OFFER,
                companyAccountId,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$item-min-width-mobile: 66%;
$item-max-width-mobile: 284px;
$item-min-width-tablet: 284px;

$item-width-with-3-items: math.div(100%, 3);
$item-width-with-2-items: math.div(100%, 2);
$item-width-with-4-items: math.div(100%, 4);
$item-width-with-5-items: math.div(100%, 5);
$item-width-with-6-items: math.div(100%, 6);

.products-simple-slider-slide {
    min-width: $item-min-width-mobile;
    max-width: $item-max-width-mobile;

    @apply px-2p;

    &:first-child {
        @apply ml-3;
    }

    &:last-child {
        @apply mr-3;
    }

    &:deep() {
        .product-card {
            @apply z-1 mb-0;

            .add-to-wishlist {
                @apply w-7 h-7 top-0 right-0;
            }
        }
    }

    @media (min-width: 430px) {
        min-width: $item-min-width-tablet;
    }

    @screen lg {
        @apply px-1;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0;
        }

        &.items-count-2 {
            min-width: $item-width-with-2-items;
        }

        &.items-count-3 {
            min-width: $item-width-with-3-items;
        }

        &.items-count-4 {
            min-width: $item-width-with-4-items;
        }

        &.items-count-5 {
            min-width: $item-width-with-5-items;
        }

        &.items-count-6 {
            min-width: $item-width-with-6-items;
        }
    }
}
</style>
